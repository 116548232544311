import React from "react";
import {Typography, Paper} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Helmet} from "react-helmet";

export default function LegitimateImmigration() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">
          Types of immigration and recommended routes we assist with.
        </title>
        <meta data-react-helmet="true" name="description" content="We facilitate many types of immigration and our consultants know the process well. Temporary, long-term, investment, family, student, medical and more."/>
        <meta data-react-helmet="true" property="og:title" content="We provide immigration services to a variety of visas/green card categories. Contact us to find one that you/family are eligible for."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/LegitimateImmigration/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Work with a reputable immigration office and establish trust, but always verify their authenticity or get references." />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-xl">Recommendation for working on your Immigration case:</h1>
        </Typography>
      </div>
      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white mb-5">
        <div className="p-5">
          <div>
            <ul className="list-decimal list-inside mb-5">
              <li>Insist on your Immigration.</li>
              <li>
                Try to work with an Immigration office even when it seems like an easy Immigration(
                on a DV lottery 30% will be disqualified after their acceptance due to errors in the
                pictures or application )
              </li>
              <li>
                Have at least minimum trust on your Immigration professional (after verifying their
                authenticity or getting reference) .
              </li>
              <li>
                Be advanced as possible (delay on submitting the immigration papers or deposit will
                make a big different)
              </li>
              <li>
                Use the email on most of your Immigration questions (not the Tel.) prints it and
                build a file.
              </li>
              <li>Show seriousness by not waiting too long between contacts or actions.</li>
            </ul>
          </div>
          <div>
            <Typography paragraph>
              You can get lots of information about the immigration in our website.
            </Typography>
          </div>
        </div>
      </Paper>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>1. Immigration through DV Lottery.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do the application for applicants and process for the winners (this immigration
              will be CANCELED in a few years)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>2. Immigration through Asylum or Refugee.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do that immigration type successfully (it is doing great lately)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>3. Immigration through Immediate Family members.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do this type of Immigration (Immigration through siblings will be CANCELED shortly)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography>4. Immigration through Marriage.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do this type of Immigration (lots of people meet over the internet and get
              legitimate marriage if faithfully)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography>5. Immigration through Investment.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>We do this type of Immigration.</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography>
              6. Student Non Immigration Visa (it may end up with Immigration)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              This type of non immigrant visa is for people in college or over 18 years old and
              under 35 years old.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            <Typography>
              7. Visitor non immigration visa (you may end up with immigration, if winning the DV or
              etc...)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Yes we do that type of non immigrant visa.</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography>8. Immigration through immediate and temporary terms.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (This kind of Immigration comes and goes according to the US needs for certain
              qualifications in certain times and we always announce it then as it happened before
              for Religious Peoples, people working for Palm Trees etc…)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            <Typography>
              9. Temporary Non immigration visa or Immigration visa for religion Scholars.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do this Immigration for Imams (it is very active now and it always turn into
              Permanent Immigration)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10bh-content"
            id="panel10bh-header"
          >
            <Typography>10. Delivering your baby in America for immigration purpose.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You will not get immigration benefits until this child will reach 21 years old, but it
              may help you with other Immigration types (this child Citizenship will be CANCELED
              shortly)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11bh-content"
            id="panel11bh-header"
          >
            <Typography>11. Immigration through Work.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Our office only helps with that type of Immigration as it is very hard now but it is
              very promising in the future.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12bh-content"
            id="panel12bh-header"
          >
            <Typography>
              12. Immigration through adoption (this type of Immigration is next to impossible in
              many Arab countries)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Our offices do not do this type of Immigration.</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel13"}
          onChange={handleChange("panel13")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13bh-content"
            id="panel13bh-header"
          >
            <Typography>13. Immigration to Canada.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>We only do immigration to Canada through skilled labor.</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel14"}
          onChange={handleChange("panel14")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14bh-content"
            id="panel14bh-header"
          >
            <Typography>14. Immigration through medical matters.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The success of this immigration type is near to impossible, a lot of people ask for
              it, but we do not do it.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel15"}
          onChange={handleChange("panel15")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15bh-content"
            id="panel15bh-header"
          >
            <Typography>
              15. Immigration through establishment of companies in California.
            </Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </div>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white my-5">
        <div className="p-5">
          <div dir="rtl">
            <Typography paragraph>
              <h2 className="text-2xl">توصيات لمتابعة ملف الهجرة:</h2>
            </Typography>
          </div>
          <div>
            <ul dir="rtl" className="list-decimal list-inside mb-5">
              <li>الإصرار على موضوع الهجرة.</li>
              <li>
                حاول أن تقدم أوراقك من خلال مكتب متخصص في خدمات الهجرة وإن بدا نوع ملفك للهجرة سهلاً
                ( في القرعة العشوائية يرفض 30% من المتقدمين بسبب الأخطاء في الصور أو الطلبات) .
              </li>
              <li>
                فليكن عندك الحد الأدنى من الثقة بمتخصص خدمات الهجرة( بعد الثقة بأصالتهم في مجال
                الهجرة أو الحصول على مرجع).
              </li>
              <li>
                كن سريعاً ومتابعاَ قدر الإمكان ( أي تأخير في إرسال أوراق الهجرة أو الرسوم يشكل فرقا
                ً كبيرا ً).
              </li>
              <li>
                إستخدم البريد الإلكتروني في معظم أسئلتك واستفساراتك عن ملفك للهجرة ولا تستخدم
                الهاتف، إطبع الرسائل الإلكترونية وشكَّل منها ملفا ً متكاملاً.
              </li>
              <li>
                أظهر جديَّك وأنك تريد فعلا ً أن تهاجر من خلال عدم الإنتظار طويلا ً بين التواصل
                والمتابعة .
              </li>
            </ul>
          </div>
        </div>

        <div dir="rtl" className="p-5">
          <Typography paragraph>
            بإمكانك الحصول على معلومات كثيرة وقيِّمة عن الهجرة من خلال زيارة موقعنا الإلكتروني.
          </Typography>
        </div>
      </Paper>

      <div>
        <Accordion
          dir="rtl"
          expanded={expanded === "panel16"}
          onChange={handleChange("panel16")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel61bh-content"
            id="panel16bh-header"
          >
            <Typography>1. الهجرة عن طريق القرعة العشوائية.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              نقوم بتقديم الطلبات عموما ً ومتابعة المعاملات الخاصًّة بالفائزين( هذا النوع من الهجرة
              سيتم إلغاؤه خلال سنوات).
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            <Typography>2. الهجرة عن طريق النزوح أو اللجوء السياسي.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>نقوم بهذا النوع من ملفًّات الهجرة بنجاح( بنجاح باهر مؤخَّراً).</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography>3. الهجرة عن طريق أفراد الأسرة المباشرين.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              نقوم بهذا النوع من الهجرة( الهجرة من خلال الإخوة والأخوات سيلغى قريباً).
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            <Typography>4. الهجرة عن طريق الزواج.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              نقوم بهذا النوع من الهجرة( الكثير من النَّاس يلتقي عبر الإنترنت و يقومون بالزواج
              قانونياً إن كان بصدق).
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "pane20"}
          onChange={handleChange("pane20")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="pane20bh-content"
            id="pane20bh-header"
          >
            <Typography>5. الهجرة عن طريق الإستثمار.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>نقوم بمتابعة هذا النوع من الهجرة.</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel21"}
          onChange={handleChange("panel21")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel21bh-content"
            id="panel21bh-header"
          >
            <Typography>6. فيزا الطالب غير المهاجر( قد ينتهي بها الأمر إلى فيزا هجرة).</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              هذا النوع من الفيزا لغير الهجرة مخصص لطلاب الجامعات أو اللذين تتراوح أعمراهم بين ال18
              وال35 سنة.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel22"}
          onChange={handleChange("panel22")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel22bh-content"
            id="panel22bh-header"
          >
            <Typography>
              7. فيزا الزائر غير المهاجر( قد ينتهي بها الأمر إلى فيزا هجرة من خلال القرعة العشوائية
              أو..).
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>نعم، نقوم بهذا النوع من الهجرة.</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel23"}
          onChange={handleChange("panel23")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel23bh-content"
            id="panel23bh-header"
          >
            <Typography>8. الهجرة من خلال الظروف الدائمة والمؤقتة.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              هذا النوع من الهجرة يتوفَّر ويلغى من خلال حاجات الولايات المتحدة الأمريكية لمهارات
              معينة في أوقات محددة ونحن نعلن عنها بمجرَّد حصولها ( رجال دين، الأشخاص أصحاب الخبرة في
              أشجار النخيل...).
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel24"}
          onChange={handleChange("panel24")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel24bh-content"
            id="panel24bh-header"
          >
            <Typography>9. الفيزا المؤقتة لغير الهجرة أو فيزا الهجرة لرجال الدين.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              نقوم بإرسال و متابعة ملفات الهجرة الهاصة بالأئمة ( فعَّال جداَ الآن وغالباَ ما تتنتهي
              إلى إقامة دائمة)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel25"}
          onChange={handleChange("panel25")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel25bh-content"
            id="panel25bh-header"
          >
            <Typography>10. ولادة إبنك في أمريكا من أجل الهجرة مستقبلا ً.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              لن تحصل على مميزات الهجرة إلا بعد أن يبلغ إبنك 21 عاماَ من العمر، ولكنها ممكن أن
              تساعدك في أنواع أخرى من طرق الهجرة( هذا النوع من الهجرة سيلغى قريباَ).
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel26"}
          onChange={handleChange("panel26")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel26bh-content"
            id="panel26bh-header"
          >
            <Typography>11. الهجرة من أجل العمل.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              مكاتبنا تساعد فقط في هذا النوع من الهجرة خصوصاَ أنه صعب جدا َ ولكنه واعد جداَ في
              المستقبل.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel27"}
          onChange={handleChange("panel27")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel27bh-content"
            id="panel27bh-header"
          >
            <Typography>12. الهجرة من خلال التبني.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              مكاتبنا لا تتابع هذا النوع من الهجرة( هو مستحيل في معظم الدول العربية).
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel28"}
          onChange={handleChange("panel28")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel28bh-content"
            id="panel28bh-header"
          >
            <Typography>13. الهجرة إلى كندا</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>نقوم بمتابعة الهجرة إلى كندا فقط عن طريق العمل المحترف.</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel29"}
          onChange={handleChange("panel29")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel29bh-content"
            id="panel29bh-header"
          >
            <Typography>14. الهجرة بسبب مشاكل صحية</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              نجاح هذا النوع من الهجرة شبه مستحيل، نحن لا نقوم به، وكان يتقدم لدينا الكثير من
              المهاجرين محاولين إتمامه.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          dir="rtl"
          expanded={expanded === "panel30"}
          onChange={handleChange("panel30")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel30bh-content"
            id="panel30bh-header"
          >
            <Typography>15. الهجرة من خلال تأسيس الشركات في كاليفورنيا.</Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
