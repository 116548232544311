import React from "react";
import {Paper, Typography, Link, Divider} from "@mui/material";

import {createTheme, ThemeProvider} from "@mui/material/styles";
import Statue from "../Images/america-Statue-of-Liberty.png";
import CompanyName from "../Component/CompanyName";
import {Helmet} from "react-helmet";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function Citizenship() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">U.S. Citizen services information for your success.</title>
        <meta
          data-react-helmet="true"
          name="description"
          content="We can help you through different issues to obtain citizenship for you or your child. Citizenship documents increasingly more required."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="High success rate immigration consultants solve any problems with citizenship process."
        />
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/Citizenship/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="We have a high success rate in resolving issues related to Language, Illness, Legal Matters, and more" />
      </Helmet>
      <div className="">
        <div className="">
          <Typography paragraph>
            <h1 className="text-2xl">US Citizenship</h1>
          </Typography>
          <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
            <div className="p-5">
              <div>
                <Typography>
                  If you have problems getting your Citizenship due to Language, Illness, Legal
                  Issues, etc., please contact us so we can help you. We have a high success rate in
                  helping people get their citizenship.
                </Typography>
              </div>
              <div className="pt-5" dir="rtl">
                <Typography paragraph>
                  <h1 className="text-2xl">الجنسية الامريكية</h1>
                </Typography>
                <Typography paragraph>
                  اذا كان لديك مشاكل في الحصول على الجنسية الامريكية مثل اللغة الانجليزية أو مشاكل
                  صحية او نفسية او مشاكل قانونية و الخ دعنا نعمل معا لحلها فلدينا نتائج عظيمة مع هذه
                  القضايا
                </Typography>
              </div>
              <div className="pt-5">
                <Typography paragraph>
                  <h1 className="text-2xl">Pursue your Child’s Citizenship</h1>
                </Typography>
                <Typography>
                  If your child has been issued a United States Passport, but does not have the
                  Citizenship Certificate, then we urge you to work on getting it for him/her
                  immediately. It is becoming more and more important to have a Citizenship
                  Certificate, as it is increasingly being requested from schools, employers, etc…
                </Typography>
              </div>
              <div className="pt-5" dir="rtl">
                <div>
                  <Typography>
                    <h2 className="text-2xl">احصل على جنسية ابنك</h2>
                  </Typography>
                </div>
                <div>
                  <Typography paragraph>
                    أذا كان أبنائك لديهم جوازات سفر أمريكية و لم يحصلوا على شهادة الجنسية عليك
                    الاسراع في الحصول عليها فى أقرب فرصة حيث تزداد الحاجة الان لتقديمها و من المنتظر
                    أن تزداد الحاجة الى وجودها اكثر فأكثر كما نرى فى بعض المدارس و الوظائف و الكثير
                    من الامور
                  </Typography>
                </div>
              </div>

              <div className="lg:flex lg:gap-5 justify-center pb-5">
                <div className="lg:w-1/4">
                  <div className="pt-2">
                    <img src={Statue} alt="" title="" className="rounded-2xl mx-auto" />
                  </div>
                </div>
                <div className="lg:w-1/4">
                  <div className="text-center pt-2">
                    <address>
                      <div>
                        <Typography>
                          <CompanyName />
                        </Typography>
                      </div>
                      <div>
                        1811 W. Katella Ave. #211 <br /> Anaheim, California 92804 - USA
                      </div>
                    </address>
                  </div>
                </div>
                <div className="lg:w-1/4">
                  <div className="text-center pt-2 mb-2">
                    <Link href="mailto:info@immigrationplus.us">
                      Email: info@immigrationplus.us
                    </Link>
                  </div>
                  <Divider className="dark:bg-[#99aab5] clear-both" />
                  <div className="text-center pt-2 mb-2">
                    <a href="tel:714-491-0748">Tel: (714) 491-0748</a>
                  </div>
                  <Divider className="dark:bg-[#99aab5] clear-both" />
                  <div className="text-center pt-2 mb-2">
                    <a href="fax:7147786877">Fax: (714) 778-6877</a>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}
