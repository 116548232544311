import React from "react";
import {Link, Typography, Paper} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import PreFooter from "../Component/preFooter";
import {Helmet} from "react-helmet";
import CompanyName from "../Component/CompanyName";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function Requirements() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Start your immigration with us. Details.</title>
        <meta
          data-react-helmet="true"
          name="description"
          content="Instructions and requirements to start immigration filing. Expert consultants increase the success rate for U.S. immigration."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Immigration consultants that know the requirements for proper immigration applications. Secure your process today."
        />
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/Requirements/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Apply now for the DV Lottery through Immigration Plus! The DV Lottery is an excellent opportunity for low-cost immigration to the U.S. Submit your completed application, passport-sized photos, and payment of $40 for 1 year or $100 for 3 years. Send via email or mail to our office for professional service and increased chances of winning. Don't miss out on this chance for you and your family to immigrate to the U.S. Apply today!" />
      </Helmet>

      <div>
        <Typography paragraph>
          <h1 className="text-2xl">DV Lottery</h1>
        </Typography>
      </div>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          <div>
            <Typography paragraph>Please send us:</Typography>
          </div>
          <div className="my-5">
            <ul className="list-decimal list-inside">
              <li>Completed DV Lottery Application</li>
              <li>
                1 passport size photo for each person on application (
                <Link>click here to see sample</Link>)
              </li>
              <li>Payment of $40 for a 1 year application, or $100 for 3 years</li>
              <ul className="list-disc pl-4">
                <li>
                  Please make check/money order payable to: <CompanyName />
                </li>
                <li>Or request Bank Deposit Information</li>
              </ul>
            </ul>
          </div>
          <div>
            <Typography paragraph>Send by: </Typography>
          </div>
          <ul className="list-disc pl-4">
            <li>
              E-mail items 1-2 and bank deposit receipt to
              <Link href="mailto:info@immigrationplus.us">info@immigrationplus.us</Link> (subject:
              DV Lottery), if paying by check send it in mail
            </li>
            <li>
              Or mail everything to: <br></br>
              <div className="pl-5">
                DV Lottery <CompanyName />
                <br></br>
                1811 W. Katella Ave. # 211<br></br>
                Anaheim, CA 92804<br></br>
                USA<br></br>
              </div>
            </li>
          </ul>

          <div>
            <Typography paragraph>
              <h1 className="text-2xl text-center">Apply Now for DV Lottery</h1>
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              We encourage you to apply for the DV Lottery now for the following reasons:
            </Typography>
          </div>

          <div className="my-5">
            <ul className="list-decimal list-inside">
              <li>
                The DV Lottery is an excellent opportunity to get low-costing immigration to the
                United States. It allows many families and individuals to immigrate to the U.S.
                every year.
              </li>
              <li>
                The application deadline may vary; the sooner you submit your application to us, the
                better.
              </li>
              <li>
                Many people lose their chance to apply due to the short time given; their
                applications are delayed because they fail to send the proper requirements in on
                time. We accept DV Lottery applications throughout the year, and encourage you to
                submit them as soon as possible.
              </li>
              <li>
                Our office provides professional service for this program; thus, increasing your
                chance of winning.
              </li>
              <li>Application options: </li>
              <ul className="list-disc pl-4">
                <li>$50 for Individuals (unmarried, aged 18 and older)</li>
                <li>
                  $75 for Families: husband, wife, and unnmarried children under 21 years; includes
                  2 chances of winning (1 chance for husband, and 1 chance for wife)
                </li>
                <li>
                  $25 for Individual applying with family and individually (unmarried, aged 18-20)
                </li>
              </ul>
            </ul>
          </div>
          <div>
            <Typography>Spread the word, and don't miss out!</Typography>
          </div>
        </div>
      </Paper>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white mt-5">
        <div className="p-5">
          <div>
            <Typography paragraph>
              <h1 className="text-2xl text-center">القرعة العشوائية – قدم الآن</h1>
            </Typography>
          </div>

          <div className="pt-5" dir="rtl">
            <Typography paragraph>الأصدقاء الأعزاء:</Typography>
            <Typography paragraph>
              نحن نحثكم على التقدم الينا الآن للقرعة العشوائية للأسباب التالية:
            </Typography>
          </div>

          <div>
            <ul className="list-decimal list-inside" dir="rtl">
              <li>
                كثير من الأفراد و العائلات يحضر كل عام للولايات المتحدة عن طريق القرعة العشوائية
                بأقل التكلفة و يجب على الجميع انتهاز هذه الفرصة.
              </li>
              <li> تاريخ التقديم يتغير في كثير من الأحيان من سنة لأخرى.</li>
              <li>
                الكثير قد تضيع عليه فرصة الحصول على ذلك نتيجة للتأخر في ارسال البيانات أو الصور
                نظراً لضيق وقت و مدة التقديم أو التقديم الخاطئ.
              </li>
              <li>
                مكتبنا قديم جدا و متخصص جدا في هذه الناحية و ذلك يقلل من نسبة الأخطاء و يزيد من نسبة
                نجاحكم.
              </li>
              <li> طرق التقديم:</li>
              <ul className="list-disc list-inside pl-4">
                <li>للفرد (غير متزوج و عمره على الأقل 18)</li>
                <li>
                  للعائلة: الزوج و الزوجة و الأولاد الغير متزوجين تحت 21 سنة و يتيح لهم فرصتين للفوز
                  عن طريق (1 للزوج و 1 للزوجة)
                </li>
                <li>للفرد الذي يقدم مع العائلة و يقدم كفرد لوحده (غير متزوج و عمره 18-20)</li>
              </ul>
            </ul>
          </div>
          <div className="pt-5" dir="rtl">
            <Typography>
              <h2 className="text-2xl mt-5">و نساعدك بأي من الطرق الآتية:</h2>
            </Typography>
          </div>
          <div>
            <ul className="list-decimal list-inside" dir="rtl">
              <li> ترجمة شهادات</li>
              <li>
                مراجعة الأوراق بدقة قبل تقديمها (لا يجوز الإستهوان لأن هذا الطلب يقرر أشياء مهمة
                بحياتك و حيات أقاربك) لإن أي غلط في تقديم الإستمارات ممكن أن يسبب لك خسارة هذه
                الفرصة اوغيرها
              </li>
              <li>تعبئت الإستمارات بإسم المحامي بشكل أفضل</li>
            </ul>
          </div>
          <div className="pt-5" dir="rtl">
            <Typography>انشر الخبر لكل عزيز و لا تتردد</Typography>
          </div>

          <PreFooter />
        </div>
      </Paper>
    </div>
  );
}
