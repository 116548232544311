import React from "react";
import {Paper, Typography} from "@mui/material";
import CompanyName from "./Component/CompanyName";
import Link from "@mui/material/Link";
import Sherif from "./Images/Sherif.jpg";
import {Helmet} from "react-helmet";

function Contact() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Contact Immigration Plus, Immigration consultant and more</title>
        <meta data-react-helmet="true" name="description" content="Our consultants are standing by for any citizenship services. Please contact us for help with all your immigration/translation needs." />
        <meta data-react-helmet="true" property="og:title" content="Contact page of experienced immigration consultants."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Contact/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Contact Immigration Plus, Our consultants are standing by for any citizenship services." />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">
            Contact <CompanyName />
          </h1>
        </Typography>
      </div>
      <div>
        <div className="">
          <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
            <div className="p-5">
              <div className="mb-5">
                <Typography>Immigration & All Other Services</Typography>
              </div>
              <div className="float-right mx-10">
                <img
                  src={Sherif}
                  alt="Sherif, Sr"
                  title="Immigration & All Other Services
Sherif, Sr.
Anaheim, California - USA
sherif_immigrationland@hotmail.com
Cell: (714) 924-6286"
                  className="rounded-lg"
                />
              </div>
              <address>
                <div>
                  <Typography gutterBottom>
                    Tel:
                    <Link
                      color={"inherit"}
                      href="tel:714-491-0781"
                      title="Call Immigration land in Anaheim, California, USA"
                    >
                      (714) 491-0781
                    </Link>
                  </Typography>
                </div>
                <div>
                  <Typography gutterBottom>
                    Office:
                    <Link
                      color={"inherit"}
                      href="tel:7149246286"
                      title="call Immigration land in Anaheim, California, USA"
                    >
                      (714) 924-6286
                    </Link>
                  </Typography>
                </div>
                <div>
                  <Typography gutterBottom>
                    1811 W. Katella Ave. Ste. # 214 - Anaheim, California 92804 - USA
                  </Typography>
                </div>
                <div>
                  <Typography gutterBottom>
                    Email:
                    <Link color={"inherit"} href="mailto:info@immigrationplus.us">
                      
                      info@immigrationplus.us
                    </Link>
                  </Typography>
                </div>
                <div>
                  <Typography gutterBottom>
                    Office:
                    <Link
                      color={"inherit"}
                      href="tel:714-491-0748"
                      title="Call Immigration land in Anaheim, California, USA"
                    >
                      (714) 491-0748
                    </Link>
                  </Typography>
                </div>
                <div>
                  <Typography gutterBottom>
                    Sherif, Sr.:
                    <Link
                      color={"inherit"}
                      href="tel:714-924-6286"
                      title="Call Immigration land in Anaheim, California, USA"
                    >
                      Cell: (714) 924-6286
                    </Link>
                  </Typography>
                </div>
                <div>
                  <Typography gutterBottom>
                    M. Shatat Cell:
                    <Link
                      color={"inherit"}
                      href="tel:949-502-1315"
                      title="Call Immigration land in Anaheim, California, USA"
                    >
                      Cell: (949) 502-1315
                    </Link>
                  </Typography>
                </div>
                <div>
                  <Typography gutterBottom>
                    Jr. Cell:
                    <Link
                      color={"inherit"}
                      href="tel:714-757-9856"
                      title="Call Immigration land in Anaheim, California, USA"
                    >
                      Cell: (714) 757-9856
                    </Link>
                  </Typography>
                </div>
              </address>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}
export default Contact;
