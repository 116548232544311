import React from "react";
import {Paper, Typography, Divider} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Button from "@mui/material/Button";
// import DL1 from "./Images/IDL-main.jpg";

import {Helmet} from "react-helmet";
import CompanyName from "./Component/CompanyName";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function DriverLicense() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Helping you apply for International Driver License.</title>
        <meta
          data-react-helmet="true"
          name="description"
          content="We can complete a drivers license application which allows driving internationally. Buy and insure car. Mail documents and receive IDL."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Assistance with IDL international drivers license services."
        />
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="" />
      </Helmet>
      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          <div>
            <Typography>
              Get your International Driver’s License to drive anywhere, worldwide. Apply for your
              International Driver’s License from the USA, UAE, Egypt, and almost anywhere else.
              Benefits of an International Driver’s License are listed below.
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              <h1 className="text-2xl text-center">
                Apply for an International Driver’s License (IDL)
              </h1>
            </Typography>
          </div>
          <div className="my-5">
            <div>
              <Typography>Please send us:</Typography>
            </div>
            <div>
              <ul className="list-decimal list-inside">
                <li> Completed & signed IDL Application (click here to download)</li>
                <li>1 passport size photo (click here to see sample) </li>
                <li>Copy of your native driver’s license</li>
                <li>Your telephone number & e-mail address</li>
                <li>Payment of $150 (IDL valid for 10 years; includes 1 card & 1 book)</li>
                <ul className="list-disc pl-4">
                  <li>
                    Please make check/money order payable to: <CompanyName />
                  </li>
                  <li>Or request Bank Deposit Information</li>
                </ul>
              </ul>
            </div>
          </div>
          <div>
            <ul className="list-disc list-inside my-5">
              <li>
                E-mail items 1-4 & bank deposit receipt to info@immigrationplus.us (subject: IDL),
                if paying by check send it in mail
              </li>
              <li>
                Or fax items 1-4 & bank deposit receipt to (714) 778-6877 (Attn: IDL), if paying by
                check send it in mail
              </li>
              <li>
                Or mail everything to:
                <div className="pl-4">
                  IDL <CompanyName />
                  <br></br>
                  1811 W. Katella Ave. # 211<br></br>
                  Anaheim, CA 92804<br></br>
                  USA<br></br>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <Typography paragraph>
              <h1 className="text-2xl">Benefits of an International Driver’s License</h1>
            </Typography>
          </div>
          <div>
            <ul className="list-decimal list-inside">
              <li>Generally, the IDL is valid all over the world.</li>
              <li>
                Unlike many countries that require a minimum age of 18 to issue a driver’s license,
                the IDL can be issued at age 16.
              </li>
              <li>
                It is inexpensive, compared to fees charged in other countries. We charge only $150,
                and it is valid for 10 years.
              </li>
              <li>The IDL is usually sufficient to buy and insure cars with.</li>
              <li>It is translated into many languages.</li>
              <li>
                We continuously issue the IDL in many areas including: USA, Europe, Middle East,
                Persian Gulf, Africa, Asia, and Latin America...
              </li>
            </ul>
          </div>
        </div>
      </Paper>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white mt-5">
        <div className="p-5">
          <div className="">
            <div className="pt-5">
              <Typography>
                <h1 className="text-2xl" dir="rtl">
                  فوائد رخصة القيادة الدولية
                </h1>
              </Typography>
            </div>
            <div className="m-2">
              <Divider className="dark:bg-[#99aab5]" />
            </div>

            <div>
              <ul className="list-decimal list-inside" dir="rtl">
                <li>رخصة القيادة الدولية صالحة في مختلف دول العالم </li>
                <div className="m-2">
                  <Divider className="dark:bg-[#99aab5]" />
                </div>
                <li>السن القانوني للحصول على الرخصة هو 16 عام بدل من 18 عام كما في مناطق اخرى</li>
                <div className="m-2">
                  <Divider className="dark:bg-[#99aab5]" />
                </div>
                <li>رخيصة مقارنة مع تكاليفها في دول اخرى. فقط 150 دولار و صالحة لمدة 10 سنوات</li>
                <div className="m-2">
                  <Divider className="dark:bg-[#99aab5]" />
                </div>
                <li>تخول حاملها شراء السيارات و التأمينات</li>
                <div className="m-2">
                  <Divider className="dark:bg-[#99aab5]" />
                </div>
                <li>مترجمة لعدة لغات</li>
                <div className="m-2">
                  <Divider className="dark:bg-[#99aab5]" />
                </div>
                <li>
                  يتم استخدامها في مختلف انحاء العالم... امريكا، اوروبا، الشرق الأوسط، افريقيا،
                  الخليج، اسيا، امريكا اللاتينية، و اماكن اخرى
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
