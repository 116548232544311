import React from "react";
import {Typography, Paper} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Helmet} from "react-helmet";

export default function BeforeYouStartYourImmigration() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Preparing for immigration process. We can help.</title>
        <meta
          data-react-helmet="true"
          name="description"
          content="Our consultants can help you get  immigration process through the path you need. Instructions and information on the different types of immigration."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="We make your immigration process correct the first time. Services to simplify your immigration needs. "
        />
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/BeforeYouStartYourImmigration/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Our team of experienced consultants can guide you through the process and help you achieve your immigration goals" />
      </Helmet>
      <div>
        <Typography paragraph>
          
          <h1 className="text-2xl">Recommendations for working on your Immigration case:</h1>
        </Typography>
      </div>
      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white mb-5">
        <div className="p-5">
          <div>
            <ul className="list-decimal list-inside mb-5">
              <li>Insist on your Immigration benefits.</li>
              <li>
                Try to work with an immigration office even when it seems like an easy process (on a
                DV lottery 30% of applications are disqualified after submission due to minor and
                major errors).
              </li>
              <li>
                Have at least minimum trust in your immigration professional (after verifying their
                authenticity or receiving a reference).
              </li>
              <li>
                Be as timely as possible (delay in submitting the immigration documents or money
                deposits can make a big difference in time).
              </li>
              <li>
                Use email for most of your immigration questions (not the telephone), print the
                correspondences and save them in a file.
              </li>
              <li>
                Show seriousness by not waiting too long between contacting an office and taking
                action.
              </li>
            </ul>
          </div>

          <div>
            <Typography paragraph>
              You can get lots of information about immigration from our website.
            </Typography>
          </div>
        </div>
      </Paper>
      <div>
        <Typography paragraph>
          <h2 className="text-2xl">Types of Immigration:</h2>
        </Typography>
      </div>

      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>1. Immigration through Investment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>We do this type of Immigration</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>2. Immigration through DV Lottery</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do the application and we can process the immigration for the winners (this
              immigration will be CANCELED in upcoming years)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>
              3. Visitor/non-immigration visa (you may end up with immigration, if winning the DV,
              or getting married etc...)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Yes, we help on that type of non immigrant visa</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography>4. Immigration through Marriage</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do this type of Immigration (lots of people meet over the Internet and get
              legitimate marriage if faithful, but they need lots of guidance on that type of
              immigration)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography>5. Immigration through Immediate Family Members</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do this type of immigration (immigration through siblings will be CANCELED in
              upcoming years)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography>
              6. Students or pilots non-immigrant visa (it may end up with immigration if you get a
              job offer etc... )
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              This type of non-immigrant visa is for people in college or over 18 years of age and
              under 35 years of age.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            <Typography>7. Immigration through Asylum</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do this type of Immigration successfully (it is doing great lately especially with
              the troubled countries like Syria, Egypt, etc...)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography>8. Delivering your baby in America</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You will not get immigration benefits until this child reaches 21 years-old, but it
              may help you with other immigration types (this child citizenship by birth will be
              CANCELED in upcoming years), you and your spouse may lose your visa to the US due to
              that delivery We do that type of Immigration successfully
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            <Typography>9. Immigration through Obama Law</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              This type of Immigration is based on: 1-Having a child or one parent holding
              Citizenship or green card 2-Being in the US for over 5 years (from November 2014)
              3-Having no criminal records. 4- Filing your taxes in meeting the moral character
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10bh-content"
            id="panel10bh-header"
          >
            <Typography>
              10. Temporary Non immigration visa or Immigration visa for religious Scholars (Imams)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We do this Immigration (it is very active now and it always turns into permanent
              Immigration as it is in demand)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11bh-content"
            id="panel11bh-header"
          >
            <Typography>11. Immigration to Canada</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>We only do immigration to Canada through skilled labor</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel21bh-content"
            id="panel12bh-header"
          >
            <Typography>12. Immigration through immediate and temporary terms</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (This kind of Immigration comes and goes according to the US needs for certain
              qualifications in certain times and we always announce it then as it happened (before
              for religious scholars, people working with palm trees, etc...)
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel13"}
          onChange={handleChange("panel13")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel31bh-content"
            id="panel13bh-header"
          >
            <Typography>13. Immigration through Work</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Our office only helps with that type of Immigration as it is very hard now but it will
              be very promising in the future
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel14"}
          onChange={handleChange("panel14")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14bh-content"
            id="panel14bh-header"
          >
            <Typography>
              14. Immigration through adoption (this type of Immigration is nearly impossible in
              many Arab countries)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Our office doesn’t do this type of Immigration</Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="pt-5">
        <Typography>Please see the rest of our website for more information</Typography>
      </div>
    </div>
  );
}
