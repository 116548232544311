import React from "react";
import {Typography, Paper} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import CompanyName from "../Component/CompanyName";
import Statue from "../Images/america-Statue-of-Liberty.png";
import {Helmet} from "react-helmet";
import PreFooter from "../Component/preFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function Asylum() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">USA Asylum information FAQ. Best chance for approval.</title>
        <meta data-react-helmet="true" name="description" content="Questions about asylum process and cost answered. Translated cases. Avoid application rejection."/>
        <meta data-react-helmet="true" property="og:title" content="Immigration consultants can help accelerate your asylum application for USCIS and translate any instructions/documents."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/Asylum/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="work permit eligibility through asylum in the United States. Our office has an excellent record of successful cases and can guide you through the process." />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">Asylum information.</h1>
        </Typography>
      </div>

      <Paper>
        <div className="p-5">
          <div>
            <Typography paragraph>
              Due to the current conditions in Arab countries, especially Egypt, Syria, and Yemen,
              asylums are among the strongest available immigration methods at this time.
            </Typography>
          </div>
          <div>
            <Typography paragraph>
              <div className="text-1xl underline font-bold">Frequently Asked Questions:</div>
            </Typography>
            <Typography paragraph>
              1. Why choose our office? None of our cases have been rejected, and we have an
              excellent record.
            </Typography>
            <Typography paragraph>
              2. Does the person applying for asylum have to be present in the United States? Yes,
              the person must be in the United States in order for us to accept his/her case.
            </Typography>
            <Typography paragraph>
              3. Is the process guaranteed? The word “guarantee” should not be used in immigration
              matters.
            </Typography>
            <Typography paragraph>
              4. How does the process begin? Choose the office that will represent you, and make a
              deposit, then your representative will guide you and begin processing your case.
            </Typography>
            <Typography paragraph>
              5. What is the cost? The cost ranges from $2,000 to $4,000, depending on the office
              you choose to work with and the nature of your case.
            </Typography>
          </div>
          <div className="pt-5" dir="rtl">
            <div>
              <Typography paragraph>
                <h1 className="text-2xl">لماذا اللجوء الى أمريكا الان</h1>
              </Typography>
            </div>

            <div>
              <Typography paragraph>
                نظرا للظروف التى تمر بها المنطقة العربية خاصة فى مصر و سوريا و اليمن وتدخل معها بعض
                الدول الأخرى حسب الحالة فإن اللجوء هو من أقوى وسائل الهجرة المتاحة الان
              </Typography>
            </div>
            <div className="pt-5" dir="rtl">
              <Typography paragraph>
                <div className="text-1xl underline font-bold">أسئلة:</div>
              </Typography>
              <Typography paragraph>
                1. لماذا مكتبكم؟ ليس لدينا أى حالات تم رفضها ويمكنكم التأكد من ذلك
              </Typography>
              <Typography paragraph>
                2. هل لابد من التواجد فى الولايات المتحدة؟ نعم لابد أن يكون الشخص متواجد فى الولايات
                المتحدة حتى نقبل الملف
              </Typography>
              <Typography paragraph>
                3. هل هى مضمونة؟ لا يستخدم تعبير"الضمان" فى أعمال الهجرة
              </Typography>
              <Typography paragraph>
                4. كيفية البدء؟ اختيار المكتب الذى ترتاح له واعطائه مقدم أتعاب وهو سوف يقوم بالعمل
              </Typography>
              <Typography paragraph>
                5. التكلفة؟ تتراوح بين 2000 الى 4000 دولار حسب المكتب والحالة
              </Typography>
            </div>
          </div>

          <PreFooter />
        </div>
      </Paper>
    </div>
  );
}
