import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Immigration1 from '../Images/Immigration-1.jpg'
import Immigration2 from '../Images/Immigration-2.jpg'
import Immigration3 from '../Images/Immigration-3.jpg'
import Immigration4 from '../Images/Immigration-4.jpg'
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import { Typography } from '@mui/material';



const images = [
    { src: Immigration1, alt: 'Alt1', title: 'USA Immigration'  },
    { src: Immigration2, alt: 'Alt2:', title: 'USA Visa'  },
    { src: Immigration3, alt: 'Alt3', title: 'USA Green Card'  },
    { src: Immigration4, alt: 'Alt3', title: 'Translation'  },
  ];

const Slideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previous = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative">
      <div className="flex items-center justify-center w-full h-[380px]">
        {images.map((image, index) => (
          <motion.img
            key={image.src}
            src={image.src}
            alt={image.alt}
            className={`absolute object-cover top-0 left-0 w-full h-full ${
              index === currentIndex ? 'opacity-100' : 'opacity-0'
            }`}
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentIndex ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          />
        ))}
        <motion.div className="absolute z-10"
  initial={{ x: -1000 }} // starting position outside the screen on the left
  animate={{ x: 0 }} // animate to the original position (0)
  transition={{ duration: 0.5 }} // transition duration in seconds
>
  <Typography className="text-[#99aab5]  bg-black/90 p-5 print:hidden">
    <h2 className='text-5xl'>{images[currentIndex].title}</h2>
  </Typography>
</motion.div>
      </div>
      <button className="absolute top-[190px] left-4 print:hidden" onClick={previous} >
        <ArrowCircleLeftRoundedIcon sx={{ fontSize: 40 }} className='text-white'/>
      </button>
      <button className="absolute top-[190px] right-4 print:hidden" onClick={next}>
        <ArrowCircleRightRoundedIcon sx={{ fontSize: 40 }} className='text-white'/>
      </button>
    </div>
  );
};

export default Slideshow;