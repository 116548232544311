import React from "react";
import {Paper, Typography} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";

import {Helmet} from "react-helmet";
import PreFooter from "../Component/preFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function FeeWaiver() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Immigration fee waiver USCIS.</title>
        <meta data-react-helmet="true" name="description" content="Utilization of fee waiver forms for eligible immigrants. Our offices can help process all necessary documents and expedite them. "/>
        <meta data-react-helmet="true" property="og:title" content="Immigration services to take advantage of all available forms, avenues and strategies to complete your immigration process."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/FeeWaiver/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Take advantage of the government fee waiver, which is available on a case-by-case basis and by filling out additional forms. " />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">Government Fee Waiver</h1>
        </Typography>
      </div>
      <Paper>
        <div className="p-5">
          <div>
            <Typography paragraph>
              Now is a better time than ever to take advantage of the government fee waiver. This
              will be done on a case by case bases and by filling additional forms.
            </Typography>
          </div>

          <div className="pt-5" dir="rtl">
            <div>
              <Typography paragraph>
                <h1 className="text-2xl">الاعفاء ات من الرسوم الحكومية</h1>
              </Typography>
            </div>

            <div>
              <Typography paragraph>
                تستطيع الان الاستفادة من الاعفاء ات من الرسوم الحكومية أكثر من أي وقت مضى يتم ذلك من
                خلال دراسة الحالة و ملئ استمارات اضافية لهذا الغرض
              </Typography>
            </div>
          </div>

          <PreFooter />
        </div>
      </Paper>
    </div>
  );
}
