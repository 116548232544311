import React from "react";
import {Link, Typography, Paper} from "@mui/material";
import {Helmet} from "react-helmet";

export default function FamilyPetition() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">
          Changing family petition for immigration. We can help.
        </title>
        <meta
          data-react-helmet="true"
          name="description"
          content="Our experience shows that immigration policy changes and families need to properly apply at any opportunity. Contact us to expedite the process."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="We can navigate you through the ever changing immigration process. Our services are designed for international use. "
        />
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/FamilyPetition/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="To ensure that your family members benefit from the opportunities available today, we urge you to take action. Don't wait until tomorrow, apply for your relatives now and secure their future." />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">Family Petition</h1>
        </Typography>
      </div>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          <div>
            <Typography paragraph>
              If you are serious about immigrating to the United States or Canada, we would like to
              inform you that the process is only expected to become more difficult. Because of the
              changes and unpredictable routes that countries around the world are taking, we are
              seeing a rise in immigration from regions such as the Middle East and North Africa.
              Those who have previously opened a case are trying very hard to expedite their
              immigration process. Therefore, we encourage you to benefit your family members
              immediately, as we cannot predict what immigration procedures will be offered
              tomorrow. Time and time again we’ve urged our clients to apply for their relatives,
              but procrastination got the better of them. Please take our advice now, while the
              opportunity is available.
            </Typography>
          </div>
          <div>
            Call us at<Link href="tel:17144910781"> 1- (714) 491-0781.</Link>
          </div>
          <div dir="rtl">
            <Typography paragraph>
              <h2 className="text-2xl">
                لا تدع اي فرصة لتهجير اقاربك الى الولايات المتحدة أو كندا تفوتك
              </h2>
            </Typography>
          </div>
          <div dir="rtl">
            <Typography paragraph>
              كنا دائما ننصح بالتقديم للهجرة لكل الاهل حتى لم يكن لديهم خطة للقدوم والآن مع الثورات
              العربية التى بدأت و لا نعلم نهايتها يتصل بنا الكثيرين لايجاد اي وسيلة لتسريع التقديم و
              لكن لا تعاطف أو تفاعل من السلطات في هذة الامور و لذلك نشجعكم على التقديم و فورا لكل من
              تستطيعون التقديم لهم على هجرة و بأسرع وقت اتصل بنا على:
            </Typography>
          </div>
          <div dir="rtl">
            <div dir='dir="ltr"'>
              <Link href="tel:17144910781"> 1- (714) 491-0781.</Link>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
