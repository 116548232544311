import React from "react";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Helmet} from "react-helmet";

export default function InvestmentImmigrationhalfmillion() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">FAQ immigration through investment USCIS</title>
        <meta data-react-helmet="true" name="description" content="Details for investment immigration visa 500,000. We can help you calculate costs, who qualifies to immigrate with you and travel/timeline."/>
        <meta data-react-helmet="true" property="og:title" content="Immigration options for those who want to invest US. Our experts can assist with the details and proper filing. "/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/InvestmentImmigrationhalfmillion/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Considering investment immigration to the United States? With the half-million dollar option, you can invest in a new business. Take advantage of this investment opportunity and potentially secure a path to permanent residency in the United States." />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">
            FAQs – INVESTMENT IMMIGRATION (half-million) (Frequently Asked Questions)
          </h1>
        </Typography>
      </div>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>1. What are the requirements?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  $500,000 (half a million dollars) invested in the United States of America as a
                  new business, improvement of a dying business, or expansion for a new project.
                </li>
                <li>Business management experience is preferred, but not required.</li>
                <li>A valid Visa to the United States (at least for the principal applicant).</li>
                <li>
                  At least 5 years of documentation for all personal and business tax returns,
                  business licenses, and proof of owning any business.
                </li>
                <li>Additional documents may be required, depending on the case.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>2. What are the fees and costs?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  Fees are about $5,000; this amount differs depending on the number of family
                  members.
                </li>
                <li>
                  Costs are approximately $75,000, which include the following aspects to
                  establishing the business that you will invest in: company structure, business
                  plan, taxes, payroll, satisfying the 10 employees hired requirement, etc…
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>3. How long does it take?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>Anticipate a 1-year duration to get a Conditional Green Card.</li>
                <li>
                  After another 2 years you should get a Permanent Green Card, depending on the
                  continuation of the investment.
                </li>
                <li>After another 3 years, you can obtain the U.S. Citizenship.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography>4. Who qualifies as a family member?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>Applicant.</li>
                <li>Spouse.</li>
                <li>Children: unmarried and under 21 years of age.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography>5. What is the guarantee?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>There is no guarantee.</li>
                <li>
                  We are highly likely to succeed if you properly comply with the requests
                  (submitting documents, being timely, being ethical, etc…).
                </li>
                <li>The $500,000 investment will always remain under your name.</li>
                <li>We have no knowledge of anyone who lost their $500,000, or any part of it.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography>6. When can I retrieve my $500,000 investment, if I need to?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>After 3 to 4 years from the start of the investment.</li>
                <li>You can also retrieve any profits made.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            <Typography>
              7. Can I travel out of the U.S. during my Investment Immigration process?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  Yes, you may travel within a few months of applying for the adjustment with a
                  2-year permit and unlimited entries.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography>8. How long should I stay in the U.S. within 1 year?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  The investor must enter the U.S. within 180 days from receiving the Visa. He/she
                  must also establish residency in the U.S. by showing an intent to stay (for
                  example, buy/rent a home, open bank accounts, apply for a social security number,
                  obtain a driver’s license, pay taxes).
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            <Typography>9. Is there anything else that I need to know at this point?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>This type of investment is typically done towards the medical field.</li>
                <li>
                  Now is a great time to do the Investment Immigration. President Obama’s strategy
                  for the 2012 re-election is to improve economic conditions by encouraging
                  Investment Immigration. Therefore, this process should be much smoother now.
                </li>
                <li>
                  The U.S. government and Immigration rules were very recently updated, which is why
                  we waited to post this fresh information.
                </li>
                <li>
                  The U.S. government and Immigration officials are very positive and serious about
                  this type of Immigration, and are optimistic about the shift it will bring to
                  future economic conditions. In addition, they are very firm about accepting
                  truthful cases: it is your duty to be truthful and serious in this process; it
                  cannot be done unethically as a means of getting an easy Green Card.
                </li>
                <li>
                  Buying a house, placing the $500,000 in a U.S. bank, or owning assets does not
                  qualify as an investment. The investment must establish a business, or enhance a
                  dying business, and must employ 10 U.S. Citizens.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10bh-content"
            id="panel10bh-header"
          >
            <Typography>
              10. Are there any problems or precautions that I should consider?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  Submitting the minimum amount of documentation (for the funds used for the
                  investment) is likely to delay and complicate the application process. Providing
                  full disclosure from the beginning will result in a smooth and timely application
                  process, and the least amount of difficulties.
                </li>
                <li>If others, they will be case specific, and you will be informed.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11bh-content"
            id="panel11bh-header"
          >
            <Typography>
              11. How is this program different from other Immigration programs?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  The Investor Visa grants permanent residence, and does not require renewal or
                  reapplying.
                </li>
                <li>There are no employment, educational, or language requirements.</li>
                <li>
                  The investor, spouse, and children under 21 are given expedited permanent
                  residence.
                </li>
                <li>The investor and family can live and/or work anywhere in the U.S.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
