import React from "react";
import {Typography, Paper, Divider} from "@mui/material";
import Link from "@mui/material/Link";
import {Helmet} from "react-helmet";
import Arabic from "./Images/Arabic.jpg";
import French from "./Images/French.jpg";
import German from "./Images/German.jpg";
import Spanish from "./Images/Spanish.jpg";
import PreFooter from "./Component/preFooter";

export default function Translation() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Detailed, certified translation immigration services.</title>
        <meta data-react-helmet="true" name="description" content="Translations from English, German, French, Arabic Spanish of any documents needed by USCIS including immigration/court documents."/>
        <meta data-react-helmet="true" property="og:title" content="Get your international documents professionally translated and certified for immigration."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Translation/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Our office offers certified document translations for a variety of languages, including Arabic, French, German, and Spanish. We specialize in providing translations for immigration and court documents such as birth certificates, marriage certificates, divorce certificates, death certificates, identification documents, police reports, college degrees, high school diplomas, baptisms, and more. In addition to our translation services, we also specialize in handling immigration and court-related matters. Trust us to provide accurate and reliable translations for all your important documents." />
      </Helmet>

      <div>
        <Typography paragraph>
          <h1 className="text-2xl">Translation</h1>
        </Typography>
      </div>
      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          Certified document translations for English to/from:
          <span className="font-bold">Arabic</span>, <span className="font-bold">French</span>,
          <span className="font-bold">German</span>, and <span className="font-bold"> Spanish</span>
          . We specialize in Immigration and Court translations for certificates including: birth,
          marriage, divorce, death, identification, police report, college degree, high school
          diploma, baptism, and others...
          <br />
          <span class="text-red-700">
            *We also specialize in Immigration and Court translations.
          </span>
        </div>

        <div className="text-center p-5">
          <div>
            <Typography>ترجمة</Typography>
          </div>
          <div>
            <Typography>العربية، الفرنسية، الألمانية، الأسبانية</Typography>
          </div>
          <div>
            <Typography>من و إلى اللغة الانجليزية</Typography>
          </div>
          <div>
            <Typography>
              أن المكتب يقدم كافة خدمات الترجمة الى اللغات المذكورة اعلاه، و بالأخص الأوراق و
              المستندات المقدمة لدائرة الهجرة و المحاكم. الرجاء الأتصال بنا لمعرفة التكلفة.
            </Typography>
          </div>
        </div>

        <div className="lg:flex lg:gap-5 mx-5 justify-center">
          <div className="lg:w-1/3 text-center">
            <div className="mb-3">
              <img src={Arabic} className="rounded-3xl mx-auto" alt="" title="" />
            </div>
            <div>
              <Typography>English to Arabic</Typography>
            </div>
            <div className="pb-5">
              <Typography>Arabic to English</Typography>
            </div>
          </div>
          <div className="lg:w-1/3 text-center">
            <div className="mb-3">
              <img src={French} className="rounded-3xl mx-auto" alt="" title="" />
            </div>
            <div>
              <Typography>English to French</Typography>
            </div>
            <div className="pb-5">
              <Typography>French to English</Typography>
            </div>
          </div>
          <div className="lg:w-1/3 text-center">
            <div className="mb-3">
              <img src={German} className="rounded-3xl mx-auto" alt="" title="" />
            </div>
            <div>
              <Typography>English to German</Typography>
            </div>
            <div className="pb-5">
              <Typography>German to English</Typography>
            </div>
          </div>
          <div className="lg:w-1/3 text-center">
            <div className="mb-3">
              <img src={Spanish} className="rounded-3xl mx-auto" alt="" title="" />
            </div>
            <div>
              <Typography>English to Spanish</Typography>
            </div>
            <div className="pb-5">
              
              <Typography>Spanish to English</Typography>
            </div>
          </div>
        </div>

        <div className="p-5">
          <Typography>
            Please send us the document you wish to translate by e-mail, fax, or mail for a quote
            (include your name, phone number, and e-mail address). Our contact information is listed
            below and on the <Link href="/Contact">Contact page</Link>
          </Typography>
        </div>
        <PreFooter />
      </Paper>
    </div>
  );
}
