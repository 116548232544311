import React, { useState, useEffect } from "react";
import "./App.css";
import Footer from "./Component/footer";
import Home from "./Home";
import USA from "./Immigration/USA";
import Contact from "./Contact";
import Translation from "./Translation";
import Link from "@mui/material/Link";
import FamilyPetition from "./Immigration/FamilyPetition";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Citizenship from "./Immigration/Citizenship";
import WorkPermit from "./Immigration/WorkPermit";
import Requirements from "./Immigration/Requirements";
import FAQs from "./Immigration/FAQs";
import Caution from "./Immigration/Caution";
import Asylum from "./Immigration/Asylum";
import FeeWaiver from "./Immigration/FeeWaiver";
import Notary from "./Notary";
import InvestmentImmigrationhalfmillion from "./Immigration/InvestmentImmigrationhalfmillion";
import InvestmentImmigrationonemillion from "./Immigration/InvestmentImmigrationonemillion";
import Immigration from "./Immigration";
import LegitimateImmigration from "./Immigration/LegitimateImmigration";
import BeforeYouStartYourImmigration from "./Immigration/BeforeYouStartYourImmigration";
import InternationalDriverLicense from "./InternationalDriverLicense";
import TungstenIcon from "@mui/icons-material/Tungsten";
import Slideshow from "./Component/Slideshow";
import CompanyName from "./Component/CompanyName";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";

const history = createBrowserHistory();

function getInitialDarkMode() {
  const savedMode = localStorage.getItem("darkMode");
  if (savedMode === null) {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
  return savedMode === "true";
}

function App() {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" ||
    (!("darkMode" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
  );
  const [navbar, setNavbar] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => setDarkMode(e.matches);
    mediaQueryList.addEventListener("change", handleChange);
    return () => mediaQueryList.removeEventListener("change", handleChange);
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString());
  }, [darkMode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const icon = darkMode ? (
    <TungstenIcon style={{ fill: "#FFFFFF" }} />
  ) : (
    <ModeNightIcon style={{ fill: "#000000" }} />
  );
  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="dark:bg-[#23272a] dark:text-[#ffffff] ">
        <BrowserRouter history={history}>
          <nav
            className="w-full border-b-8 border-[#99aab5] dark:text-[#99aab5]"
            darkMode={darkMode}
          >
            <div className="justify-between mx-auto lg:max-w-7xl md:items-center md:flex md:px-0">
              <div>
                <div className="flex items-center justify-between md:py-0 md:block">
                  <Link href="/" style={{ textDecoration: "none" }} color={"inherit"}>
                    <div className="font-bold no-underline mt-4 ml-5">
                      <h1 className="text-3xl uppercase mb-0"><CompanyName /></h1>
                      <div className="float-right dark:text-white"><Typography variant="caption">Immigration Services, Consultant</Typography></div>
                    </div>

                  </Link>
                  <div className="md:hidden print:hidden">
                    <button
                      className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                      onClick={() => setNavbar(!navbar)}
                    >
                      {navbar ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={`flex-1 justify-self-center pb-1 mt-1 md:block md:pb-0 md:mt-0 ${navbar ? "block" : "hidden"
                    }`}
                >
                  <ul className="sm:text-black md:flex md:space-x-0 md:space-y-0 ">


                    <Link
                      href="/"
                      className="dark:text-[#99aab5]"
                      color={"inherit"}
                      style={{ textDecoration: "none" }}
                    >

                      <motion.li whileHover={{ scale: 1.2 }}>
                        <div className="bg-[#23272a] dark:bg-[#2c2f33] text-[#99aab5] hover:text-white px-5 py-7 font-bold border-r-2 border-white">
                          Home
                        </div>
                      </motion.li>
                    </Link>
                    <Link
                      href="/Immigration/USA"
                      className="dark:text-[#99aab5]"
                      color={"inherit"}
                      exact="true"
                      style={{ textDecoration: "none" }}
                    >
                      <motion.li whileHover={{ scale: 1.2 }}>
                        <div className="bg-[#23272a] dark:bg-[#2c2f33] text-[#99aab5] hover:text-white px-5 py-7 font-bold border-r-2 border-white">
                          USA Immigration
                        </div>
                      </motion.li>
                    </Link>

                    <Link
                      href="/Translation"
                      className="dark:text-[#99aab5]"
                      color={"inherit"}
                      exact="true"
                      style={{ textDecoration: "none" }}
                    >
                      <motion.li whileHover={{ scale: 1.2 }}>
                        <div className="bg-[#23272a] dark:bg-[#2c2f33] text-[#99aab5] hover:text-white px-5 py-7 font-bold border-r-2 border-white">
                          Translation
                        </div>
                      </motion.li>
                    </Link>

                    <Link
                      href="/Contact"
                      className="dark:text-[#99aab5]"
                      color={"inherit"}
                      exact="true"
                      style={{ textDecoration: "none" }}
                    >
                      <motion.li whileHover={{ scale: 1.2 }}>
                        <div className="bg-[#23272a] dark:bg-[#2c2f33] text-[#99aab5] hover:text-white px-5 py-7 font-bold border-r-2 border-white">
                          Contact
                        </div>
                      </motion.li>
                    </Link>
                    <Link
                      href="/Contact"
                      className="dark:text-[#99aab5]"
                      color={"inherit"}
                      exact="true"
                      style={{ textDecoration: "none" }}
                    ></Link>
                    <li onClick={() => setDarkMode(!darkMode)} className="cursor-pointer">
                      <div className="bg-[#99aab5] text-[#99aab5] hover:bg-white px-5 py-[27px] dark:bg-[#2c2f33]">
                        {icon}
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <div className="w-full h-[380px]"><Slideshow /></div>
          <div className=" dark:text-[#ffffff]">
            <div className="container mx-auto p-7">
              <Switch>
                <Route exact="true" path="/" component={Home} />
                <Route exact="true" path="/Immigration" component={Immigration}></Route>
                <Route
                  exact="true"
                  path="/Immigration/FamilyPetition"
                  component={FamilyPetition}
                ></Route>
                <Route
                  exact="true"
                  path="/Immigration/BeforeYouStartYourImmigration"
                  component={BeforeYouStartYourImmigration}
                ></Route>
                <Route
                  exact="true"
                  path="/Immigration/LegitimateImmigration"
                  component={LegitimateImmigration}
                ></Route>

                <Route exact="true" path="/Immigration/USA" component={USA}></Route>
                <Route exact="true" path="/Immigration/Citizenship" component={Citizenship}></Route>
                <Route exact="true" path="/Immigration/WorkPermit" component={WorkPermit}></Route>
                <Route
                  exact="true"
                  path="/Immigration/Requirements"
                  component={Requirements}
                ></Route>
                <Route exact="true" path="/Immigration/FAQs" component={FAQs}></Route>
                <Route exact="true" path="/InternationalDriverLicense" component={InternationalDriverLicense}></Route>
                <Route exact="true" path="/Immigration/Caution" component={Caution}></Route>
                <Route exact="true" path="/Immigration/Asylum" component={Asylum}></Route>
                <Route exact="true" path="/Immigration/FeeWaiver" component={FeeWaiver}></Route>
                <Route
                  exact="true"
                  path="/Immigration/InvestmentImmigrationhalfmillion"
                  component={InvestmentImmigrationhalfmillion}
                ></Route>
                <Route
                  exact="true"
                  path="/Immigration/InvestmentImmigrationonemillion"
                  component={InvestmentImmigrationonemillion}
                ></Route>


                <Route exact="true" path="/Contact" component={Contact}></Route>
                <Route exact="true" path="/Translation" component={Translation}></Route>
                <Route exact="true" path="/Notary" component={Notary}></Route>
              </Switch>
            </div>
          </div>
        </BrowserRouter>
        <div className="container mx-auto p-7 dark:text-[#23272a]">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
