import React from "react";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Helmet} from "react-helmet";

export default function FAQs() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">FAQ process and requirements for immigration.</title>
        <meta data-react-helmet="true" name="description" content="Some answers for most asked questions. Eligibility, family qualifications, fees, timeframe and lottery. Immigration services help you find the best way."/>
        <meta data-react-helmet="true" property="og:title" content="FAQ and immigration services for best path to citizenship/visa/permits USCIS."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/FAQs/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="DV Lottery: Requirements, Application Fees, Qualifications, and More" />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">FAQs – DV LOTTERY (Frequently Asked Questions)</h1>
        </Typography>
      </div>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>1. What is the DV Lottery?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  55,000 annual drawings of randomly selected applicants who qualify for the strict
                  eligibility requirements
                </li>
                <li>Selected applicants and their families win a United States Green Card</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>2. What are the requirements?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>At least 18 years of age</li>
                <li>
                  At least a high school education or equivalent, or 2 years of work experience (in
                  the last 5 years) at a job that requires at least 2 years of training/experience
                </li>
                <li>Capable of paying for travel ticket</li>
                <li>Financially capable of starting a new life in the US</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>3. What are the application fees?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>We charge $50 for individual applicants</li>
                <li>$75 for families (includes a chance for each spouse’s application)</li>
                <li>
                  Additional $25 for family member aged 18-20 to apply individually (3rd chance at
                  winning)
                </li>
                <li>These fees are strictly for applying for the DV Lottery through our office</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography>4. How long does it take?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>The duration for applying may vary</li>
                <li>
                  Results are posted in May-June and actions should be taken by September 30 of the
                  following year
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography>5. Who qualifies as a family member?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>Applicant</li>
                <li>Spouse</li>
                <li>
                  Children: unmarried and under 21 years of age
                  <ul className="list-disc list-inside pl-10" style={{listStyleType: "none"}}>
                    <li>i. Aged 18-20 can also apply as an individual</li>
                    <li>ii. Includes legally adopted children and step children</li>
                    <li>iii. Excluding any eligible children will result in disqualification</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography>6. What is the guarantee?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>There is no guarantee; it is a random drawing selection process</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            <Typography>7. Can I travel out of the US during this process?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  If you are in the US (in status) you can travel after about 3-4 months with a
                  2-year permit and unlimited entries
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography>8. Is there anything else that I need to know at this point?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  We consider this to be the best method for immigrating, as the US selects you, and
                  it requires the lowest cost
                </li>
                <li>
                  Many people are determined to apply every year for their chance to win, but they
                  need to be applying correctly, and this is where we can help them
                </li>
                <li>
                  On the other hand, other people are not interested in applying for the DV Lottery,
                  yet they look for more difficult ways to immigrate
                </li>
                <li>We are confident in our services, and we have a very high success rate</li>
                <li>We contact you only if you are a selected winner</li>
                <li>You may continue working with us after you are selected</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          className="dark:bg-[#99aab5]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            <Typography>
              9. Are there any problems or precautions that I should consider?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className="list-disc list-inside" style={{listStyleType: "lower-alpha"}}>
                <li>
                  Although we strongly suggest that you apply through a professional office, we
                  caution you of businesses who falsely claim that you have won the Lottery and
                  request unnecessary money from you (this includes websites that falsify themselves
                  as the US government). Often times, actual winners may lose their chance because
                  they followed a scam. If you receive something that looks suspicious, we encourage
                  you to make an appointment with a US Embassy official to determine its legitimacy,
                  or you can forward it to us for our expertise
                </li>
                <li>
                  Selected winners should not miss the September 30th deadline, or else you will
                  definitely lose your chance of immigrating
                </li>
                <li>Submitting an Affidavit of Support is no longer required for immigrating</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
