import React from 'react'
import { Typography, Divider, Link } from '@mui/material'
import CompanyName from './CompanyName'
import Statue from "../Images/america-Statue-of-Liberty.png";

export default function PreFooter() {
  return (
    <div>
        <div className="lg:flex lg:gap-5 justify-center pb-5">
  <div className="lg:w-1/4">
  <div className="pt-2">
            <img src={Statue} alt="citizenship, visa to USA, work permit, USA Green card renewal" title='Immigration Plus, Citizenship, Visa to USA, Work Permit, USA Green Card Renewal, Immigration to USA' className="rounded-2xl mx-auto" />
          </div>
  </div>
  <div className="lg:w-1/4">
    <div className="text-center pt-2">
    <address>
            <div>
              <Typography>
                <CompanyName />
              </Typography>
            </div>
            <div>
              1811 W. Katella Ave. #211 <br /> Anaheim, California 92804 - USA
            </div>
          </address>
    </div>
  </div>
  <div className="lg:w-1/4">
    <div className="text-center pt-2 mb-2"><Link href="mailto:info@immigrationplus.us" title='Email Immigration Plus'>Email: info@immigrationplus.us</Link></div>
    <Divider className="dark:bg-[#99aab5] clear-both" />
    <div className="text-center pt-2 mb-2"><a href="tel:714-491-0748" title='Call Immigration Plus'>Tel: (714) 491-0748</a></div>
    <Divider className="dark:bg-[#99aab5] clear-both" />
    <div className="text-center pt-2 mb-2"><a href="fax:7147786877" title='Send a fax to Immigration Plus'>Fax: (714) 778-6877</a></div>
  </div>
</div>
    </div>
  )
}
