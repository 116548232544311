import React from "react";
import {Button, Link, Typography, Paper} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import "./App.css";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import {Helmet} from "react-helmet";
import Translate from "./Images/Translate.jpg";
import Fingerprinting from "./Images/Fingerprinting.png";
import IDL from "./Images/IDL.jpg";
import USCitizenship from "./Images/US-Citizenship.jpg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

function Home() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Immigration assistance citizenship, visas, translation other services.</title>
        <meta data-react-helmet="true" name="description" content="Consultants for immigration, translation, to U.S. Customer service for proper notarized documentation/application along with other services."/>
        <meta data-react-helmet="true" property="og:title" content="Secure your immigration/paperwork with USCIS. Visas, renewals, translation, notary services."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="We offer assistance in applying for U.S. Citizenship, Green Card, Visa, Work Permit, and more. We also specialize in certified document translations for immigration and court purposes, including birth, marriage, divorce, and identification certificates. Need notary, fingerprinting, or power of attorney services? We can help with that too. Plus, get your International Driver’s License from almost anywhere in the world, with 10-year validity." />

      </Helmet>
      <div className="lg:flex lg:gap-10">
        <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
          <div className="">
            <div className="p-5">
              <div className="sm:mb-5">
                <img
                  src={USCitizenship}
                  alt=""
                  title=""
                  className="float-left mx-auto mr-5 lg:mb-10 rounded-full border-2 border-[#2c2f33] dark:border-[#99aab5]"
                />
              </div>

              <div>
                <Typography paragraph>
                  For individuals/families who are interested in moving to America, we offer
                  assistance in applying for U.S. Citizenship, U.S. Green Card, and more such as:
                </Typography>
              </div>
              <div className="py-5 float-right">
                <ThemeProvider theme={theme}>
                  <Button variant="contained">
                    <Link
                      color={"inherit"}
                      style={{textDecoration: "none", fontWeight: "bold"}}
                      href="/Immigration"
                    >
                      Read more <ArrowCircleRightRoundedIcon />
                    </Link>
                  </Button>
                </ThemeProvider>
              </div>
              <div>
                <Typography>Visa to USA</Typography>
              </div>
              <div>
                <Typography>USA DV Lottery</Typography>
              </div>
              <div>
                <Typography>USA Work Permit</Typography>
              </div>
              <div>
                <Typography>USA Green Card Renewal</Typography>
              </div>
              <div>
                <Typography>
                  <Link href="/Immigration/USA" color={"inherit"} style={{textDecoration: "none"}}>
                    Immigration to USA
                  </Link>
                </Typography>
              </div>
            </div>
          </div>
        </Paper>

        <div className="lg:w-1/2 print:hidden">
          <div className="lg:float-right mb-5 mt-7">
            <iframe
              className="rounded-3xl border-2 mx-auto w-full aspect-video align-middle"
              width="420"
              height="236.25"
              src="https://www.youtube.com/embed/PYSrOAfnZP8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white mt-5">
        <div className="p-5">
          <div className="float-left mr-5 clear-both">
            <img
              src={Translate}
              alt=""
              title=""
              className="rounded-full border-2 border-[#2c2f33] dark:border-[#99aab5]"
            />
          </div>
          <Typography paragraph>
            <h3 className="text-xl">Translation</h3>
          </Typography>
          <div>
            <Typography>
              Certified document translations for English to/from: Arabic, French, German, and
              Spanish. We specialize in Immigration and Court translations for certificates
              including: birth, marriage, divorce, death, identification, police report, college
              degree, high school diploma, baptism.
            </Typography>
          </div>
          <div className="text-right mt-5 clear-left">
            <ThemeProvider theme={theme}>
              <Button variant="contained">
                <Link
                  color={"inherit"}
                  style={{textDecoration: "none", fontWeight: "bold"}}
                  href="/Translation"
                >
                  Read more <ArrowCircleRightRoundedIcon />
                </Link>
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </Paper>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white mt-5">
        <div className="p-5 ">
          <div className="float-left mr-5 clear-both">
            <img
              src={Fingerprinting}
              alt=""
              title=""
              className="rounded-full border-2 border-[#2c2f33] dark:bg-[#99aab5] dark:border-[#99aab5]"
            />
          </div>
          <Typography paragraph>
            <h3 className="text-xl">Notary, Fingerprinting & More</h3>
          </Typography>
          <div>
            <div>
              <Typography>Our services include:</Typography>
            </div>
            <ul className="list-disc list-inside">
              <li>Notary Public</li>
              <li>Fingerprinting on cards</li>
              <li>Power of Attorney: Jordan, Egypt, Syria, Saudia Arabia, Yemen...</li>
              <li>Dealing with Embassies</li>
              {/* <li>Visa to USA</li> */}
              {/* <li>Work Permit</li> */}
              <li>USA Passport Expedite (urgent, issued in 1 day)</li>
              <li>Travel Document</li>
            </ul>
          </div>
          <div className="text-right mt-5">
            <ThemeProvider theme={theme}>
              <Button variant="contained">
                <Link
                  color={"inherit"}
                  style={{textDecoration: "none", fontWeight: "bold"}}
                  href="/Notary"
                >
                  Read more <ArrowCircleRightRoundedIcon />
                </Link>
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </Paper>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white mt-5">
        <div className="p-5 rounded-xl border-black dark:border-[#99aab5] relative">
          <div className="float-left mr-5 clear-both">
            <img
              src={IDL}
              alt=""
              title=""
              className="rounded-full border-2 border-[#2c2f33] dark:border-[#99aab5]"
            />
          </div>
          <Typography paragraph>
            <h3 className="text-xl">International Driver's License</h3>
          </Typography>
          <div>
            <Typography>
              Get your International Driver’s License to drive anywhere, worldwide. Apply for your
              International Driver’s License from the USA, UAE, Egypt, and almost anywhere else.
              Benefits of an International Driver’s License include 10 year validity...
            </Typography>
          </div>
          <div className="text-right mt-5">
            <ThemeProvider theme={theme}>
              <Button variant="contained">
                <Link
                  color={"inherit"}
                  style={{textDecoration: "none", fontWeight: "bold"}}
                  href="/InternationalDriverLicense"
                >
                  Read more <ArrowCircleRightRoundedIcon />
                </Link>
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </Paper>
    </div>
  );
}
export default Home;
