import React from "react";
import {Link, Typography, Paper} from "@mui/material";
import USAVisaApp from "../Application/Visa-to-USA.pdf";
import PreFooter from "../Component/preFooter";
import {Helmet} from "react-helmet";
import USACityLights from "../Images/USACityLights.jpg";

export default function USA() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">U.S. immigration best options and assistance we service.</title>
        <meta data-react-helmet="true" name="description" content="Types of USCIS immigration including, family, investment. Everything you need to know to find the best path to citizenship/immigration. We can help."/>
        <meta data-react-helmet="true" property="og:title" content="Find what you need to immigrate to U.S. Our consultants are standing by to help you through the complex process."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/USA/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="We offer comprehensive immigration services to individuals and families looking to move to America. Our services include assistance with U.S. Citizenship, Green Card, Visa, Work Permit, and more. Contact us today to learn more." />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">Immigration to USA</h1>
        </Typography>
      </div>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          <div>
            <Typography>
              For individuals/families who are interested in moving to America, we offer assistance
              in applying for U.S. Citizenship, U.S. Green Card, and more such as:
            </Typography>
          </div>
        </div>
        <div className="mx-10">
          <img
            src={USACityLights}
            alt=""
            className="lg:float-right border-4 border-[#2c2f33] dark:border-[#99aab5]"
          />
        </div>
        <div className="px-10">
          <ul className="list-disc list-inside">
            <li>
              <Link href="/Immigration/Citizenship">Citizenship</Link>
            </li>
            <li>
              Visa to USA
              <ul className="list-disc pl-10">
                <li>
                  <Link href={USAVisaApp} target="_blank">
                    Download Application
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="/Immigration/WorkPermit">Work Permit</Link>
            </li>
            <li>USA Green Card Renewal</li>
            <li>
              Immigration to USA
              <ul className="list-disc pl-10">
                <li>
                  DV Lottery
                  <ul className="list-disc pl-10">
                    <li>
                      <Link href="/Immigration/Requirements">Requirements</Link>
                    </li>
                    <li>
                      <Link href="/Immigration/FAQs">FAQ</Link>
                    </li>
                    <li>
                      <Link href="/Immigration/Caution">Caution</Link>
                    </li>
                  </ul>
                </li>
                <li>Marriage</li>
                <li>
                  <Link href="/Immigration/FamilyPetition">Family</Link>
                </li>
                <li>
                  <Link href="/Immigration/Asylum">Asylum</Link>
                </li>
                <li>
                  <Link href="/Immigration/InvestmentImmigrationhalfmillion">
                    $500,000 Investment
                  </Link>
                </li>
                <li>
                  <Link href="/Immigration/InvestmentImmigrationonemillion">
                    $1,000,000 Investment
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link href="/Immigration/FeeWaiver">Fee Waiver</Link>
            </li>
          </ul>
        </div>

        <PreFooter />
      </Paper>
    </div>
  );
}
