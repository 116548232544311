import React from "react";
import {Paper, Typography} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import PreFooter from "../Component/preFooter";
import {Helmet} from "react-helmet";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function Caution() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">
          A warning about fraudulent scams using UCIS fake immigration.
        </title>
        <meta
          data-react-helmet="true"
          name="description"
          content="Use caution when reading any letters/correspondence trying to get information/money. Reports of these scams can easily lead people to think they are real."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Reports of fraudulent immigration communication used to scam people. Targeting U.S. Contact us to authenticate."
        />
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/Caution/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Our office is available to assist you with legitimate immigration applications and services." />
      </Helmet>

      <Typography paragraph>
        <h1 className="text-2xl text-center">WARNING - DV LOTTERY SCAM</h1>
      </Typography>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          <div>
            <Typography paragraph>
              We have received forwards of several e-mails concerning DV Lottery scams where people
              are receiving congratulatory letters/e-mails about winning the DV Lottery. Although
              the real process does require a fee payment, if the fraudulent party requests fees,
              then your payment is as good as gone.
            </Typography>
          </div>
          <div>
            <ul className="list-disc list-inside pl-4">
              <Typography>
                Our office conducted investigations with the U.S. authorities and found many of
                these congratulatory claims to be fraudulent. Please consider that it is very easy
                to fall for a scam if it is done by a professional fraud, which may have any of the
                following descriptions:
              </Typography>
              <li>
                Office located outside the U.S. to make it difficult for U.S. authorities to
                influence them (laws will be different in other countries)
              </li>
              <li>
                Office is within the U.S., and sells your information to another party who actually
                performs the scam
              </li>
            </ul>
          </div>
          <div className="my-5">
            <Typography paragraph>
              We have received forwards of several e-mails concerning DV Lottery scams where people
              are receiving congratulatory letters/e-mails about winning the DV Lottery. Although
              the real process does require a fee payment, if the fraudulent party requests fees,
              then your payment is as good as gone.
            </Typography>
          </div>
          <div className="pt-5" dir="rtl">
            <Typography paragraph>
              <h1 className="text-2xl text-center">تحذيرهام بخصوص القرعه العشوائيه </h1>
            </Typography>
            <Typography paragraph>
              وصلنا مؤخراً عدة مراسلات بخصوص اشخاص وصلهم أنهم حصلوا على القرعه العشوائية للهجرة الى
              امريكا وبالكشف عنها مع السلطات الامريكيه تبين ان معظمها كان فخ فاسد. تقوم الشركات
              بالاعلان وتقوم أنت بالتعامل معهم بحسن نية وترسل بياناتك بعدها لأننا نعلم أن المكاتب
              أخطاؤها في التقديم أقل بكثير مما يعطي فرصه أكبر للنجاح.
            </Typography>
            <div>
              <ul className="list-disc list-inside pl-4">
                <li>
                  تقوم هذه الشركات بمراسلتك بصورة أخرى أنك قد نجحت في التقديم وتطلب منك الرسوم ومعظم
                  هذه المكاتب تبين انها ليست من داخل أمريكا وذلك حتى يصعب ملاحقتهم لوقوعهم بين
                  دولتين لهما قوانين مختلفة
                </li>

                <li>
                  أو أن تبيع الشركة التي لديها بياناتك ومعلوماتك بالسوق كما هو متعارف عليه هنا وتقوم
                  الشركة المشترية بعمل هذا الملعوب
                </li>
              </ul>
            </div>
            <div className="my-5">
              <Typography paragraph>
                ورغم أننا نعتمد غالباً في الوصول للعملاء على من يزكوننا والعملاء الدائمين الا أننا
                أيضاً نعلن عن القرعة العشوائية الا أننا نحذر اعطائك بياناتك لمن لا تعرفه. تحذير أخر
                هو أننا نسمع كل عام عن أن القرعة العشوائية قد انتهت وهذا غير حقيقي لسنوات عديدة
                قادمة انشاء الله. الحقيقة ان ميعاد التقديم و النتيجة قد يتغير.
              </Typography>
            </div>
          </div>

          <PreFooter />
        </div>
      </Paper>
    </div>
  );
}
