import React from "react";
import {Link, Typography, Divider, Paper} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CompanyName from "../Component/CompanyName";
import Statue from "../Images/america-Statue-of-Liberty.png";
import {Helmet} from "react-helmet";
import PreFooter from "../Component/preFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function WorkPermit() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Student/work permit eligibility information.</title>
        <meta data-react-helmet="true" name="description" content="Detailed requirements and process for work permit application. Timelines for those exempt from removal and can apply for permit for immigration."/>
        <meta data-react-helmet="true" property="og:title" content="Immigration service walkthrough the USCIS details and help you determine eligibility or apply for any other options."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/WorkPermit/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Eligible individuals will be considered for release from removal or from entering a removal process and may be granted a Work Permit for two years, subject to renewal. To qualify, you must meet specific conditions, such as being under 16 years old when you came to the US, having continuously lived in the US for at least 5 years, and not having been convicted of certain offenses. Contact us to start the Work Permit application process." />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">Work Permit Eligibility</h1>
        </Typography>
      </div>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          <div>
            <Typography paragraph>
              President Obama has passed a law in favor of individuals who have been brought to the
              United States at a young age and do not pose as a risk to public safety or national
              security. Those individuals who meet certain conditions will be considered for release
              from removal from the U.S., or from entering a removal process. Eligible individuals
              will be relieved for two years, subject to renewal, and will be eligible to apply for
              a Work Permit.
            </Typography>
          </div>
          <div>
            <Typography paragraph>If you:</Typography>
          </div>
          <div className="my-5">
            <ul className="list-decimal list-inside">
              <li>Were under the age of 16 when you came to the United States,</li>
              <li>
                Have continuously lived in the U.S. for at least 5 years before June 15, 2012, and
                were present in the U.S. on that date,
              </li>
              <li>
                Are currently in school, have graduated from high school, have obtained a general
                education development certificate, or are a discharged veteran of the U.S. Coast
                Guard or Armed Forces,
              </li>
              <li>
                Have not been convicted of a felony offense, a significant misdemeanor offense,
                multiple misdemeanor offenses, or are not a threat to public safety or national
                security
              </li>
              <li>And were under 31 years of age by June 15, 2012 </li>
            </ul>
          </div>
          <div>
            <Typography paragraph>
              … then please
              <Link href="/Contact" color={"inherit"}>
                
                contact us
              </Link>
              to begin processing your Work Permit.
            </Typography>
          </div>
        </div>
      </Paper>
      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white mt-5">
        <div className="p-5">
          <div className="pt-5" dir="rtl">
            <Typography>
              <h2 className="text-2xl">
                قانون هجرة يمنح الاقامة المؤقتة للملايين من دون الثلاثين عاما
              </h2>
            </Typography>
          </div>
          <div dir="rtl">
            <Typography>
              
              ابتداءاً من الخامس عشر من الشهر الجاري، سيفتح باب التقدم بطلبات الحصول على الإقامة
              المؤقتة وإذن العمل أو تأجيل ترحيلهم لفترة زمنية . فالملايين كانوا بإنتظار معرفة تفاصيل
              أكثر عن مدى أهليتهم للإنتفاع من هذا القانون والإستفادة منه، وأيضاً معرفة الخطوات التي
              يجب إتباعها للحصول على الإقامة المؤقتة، وسوف نستعرض معكم هذه الخطوات، ولكن سوف نتوقف
              لبرهه من الوقت لاستعراض الشروط التي يجب أن تتوفر لدى الأشخاص الذين ينطبق عليهم
              القانون، والتي قد أتينا على ذكرها في مقالتنا السابقة، وهي كالتالي :
            </Typography>
          </div>

          <div>
            <ul className="list-decimal list-inside" dir="rtl">
              <li> أن يكون الشخص أقل من 31 عاماً حتى تاريخ 15 يونيو 2012 .</li>
              <li>أن يكون قد قدم إلى الولايات المتحدة الأمريكية قبل عمر السادسة عشرة.</li>
              <li>
                أقام في الولايات المتحدة الأمريكية بصورة مستمرة منذ تاريخ 15 يونيو عام 2007 حتى
                يومنا الحاضر .
              </li>
              <li>
                أن يكون متواجد فعلياً بالولايات المتحدة الأمريكية بتاريخ 15 يونيو 2012 وأيضاً وقت
                التقدم بالطلب .
              </li>
              <li>
                أن يكون قد دخل الولايات المتحدة بدون تأشيرة دخول قبل تاريخ 15 يونيو 2012 أو إذا قد
                تم إنتهاء إقامته الشرعية في الولايات المتحدة حتى تاريخ 15 يونيو 2012.
              </li>
              <li>
                أن يكون طالباً أو قد حصل على شهادة الثانوية العامة، أو قد حصل على شهادة التعليم
                الأولى أو أن يكون قدم خدم في الجيش أو القوات المسحلة الأمريكية .
              </li>
              <li>لم يتم اتهامه بأي جناية و لم يُحدث أي تهديد للأمن القومة والسلامة العامة .</li>
            </ul>
          </div>
          <div className="pt-5" dir="rtl">
            <Typography>
              <h2 className="text-2xl mt-5">و نساعدك بأي من الطرق الآتية:</h2>
            </Typography>
          </div>
          <div>
            <ul className="list-decimal list-inside" dir="rtl">
              <li> ترجمة شهادات</li>
              <li>
                مراجعة الأوراق بدقة قبل تقديمها (لا يجوز الإستهوان لأن هذا الطلب يقرر أشياء مهمة
                بحياتك و حيات أقاربك) لإن أي غلط في تقديم الإستمارات ممكن أن يسبب لك خسارة هذه
                الفرصة اوغيرها
              </li>
              <li>تعبئت الإستمارات بإسم المحامي بشكل أفضل</li>
            </ul>
          </div>
          <PreFooter />
        </div>
      </Paper>
    </div>
  );
}
