import React from "react";
import {Link, Paper, Typography} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CompanyName from "./Component/CompanyName";
import Statue from "./Images/america-Statue-of-Liberty.png";
import {Helmet} from "react-helmet";
import PreFooter from "./Component/preFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function Notary() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Included Notary, Fingerprinting, legal document services. </title>
        <meta data-react-helmet="true" name="description" content="Notary services that certify and authenticate legal documents accepted by USCIS. Immigration consultants visas, lottery and other applications."/>
        <meta data-react-helmet="true" property="og:title" content="Certified recorded legal documents notarized for immigration purposes in different languages."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Notary/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="Our office provides a variety of services including notary public, authentication of documents, passport expediting, travel arrangements, and more. We can also assist with immigration matters such as visas, work permits, and asylum. Additionally, we provide international driver's licenses, resume and job search assistance, and more. Our experienced team is here to help you with any issue or complication you may face, so don't hesitate to contact us for a consultation." />
      </Helmet>
      <div>
        <Typography paragraph>
          <h1 className="text-2xl">Our services include:</h1>
        </Typography>
      </div>

      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          <div className="my-5">
            <ul className="list-disc pl-10">
              <li>Fingerprinting on cards (we can come to you)</li>
              <li>Notary Public (we can come to you)</li>
              <li>
                Authentication of documents (County Clerk, Secretary of State, US State Department)
                Power of
              </li>
              <li>Attorneys, Certificate Translations (Arabic, Spanish, French, and German)</li>
              <li>USA Passport Expedite/urgent (issued in 1 day)</li>
              <li>Packages, Cruises, Honey moons, Hajj and Umrah.</li>
              <li>Benefits for frequent travelers</li>
              <li>
                International driver’s license (starting from 16 years old and valid for 10 years)
              </li>
              <li>Résumé (CV), Cover Letter, Job Search</li>
              <li>Work Permit</li>
              <li>Travel Document</li>
              <li>Visa to USA (click here to download application)</li>
              <li>Issues with breaking your Residency and Citizenship problems</li>
              <li>Asylum.</li>
              <li>DV Lottery.</li>
              <li>Excellent experience in dealing with Embassies</li>
              <li>Delivering your baby in Los Angeles, California</li>
              <li>Syrian Temporary Protected Status (TPS)</li>
              <li>Student F Visa.</li>
            </ul>
          </div>
          <div>
            <Typography paragraph>
              <h1>
                Please <Link href="/Contact">contact us</Link> for information regarding these
                services.
              </h1>
            </Typography>
          </div>
          <PreFooter />
        </div>
      </Paper>
    </div>
  );
}
