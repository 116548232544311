import React from "react";
import {Button, Divider, Link, Typography, Paper} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import USAIcon from "./Images/usa.png";
import {Helmet} from "react-helmet";

const theme = createTheme({
  palette: {
    primary: {
      main: "#99aab5", // custom primary color
    },
    secondary: {
      main: "#ef6c00", // custom secondary color
    },
  },
});

export default function Immigration() {
  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Prepare and start your U.S. immigration with expert help.</title>
        <meta data-react-helmet="true" name="description"content="Experienced immigration consultants can help you prevent any problems with U.S. immigration papers, even with unique needs."/>
        <meta data-react-helmet="true"property="og:title"content="Take advantage of professional Immigration consultants to have accurate documentation."/>
        <meta data-react-helmet="true" property="og:type" content="business.business" />
        <meta data-react-helmet="true" property="og:url" content="https://immigrationland.net/Immigration/" />
        <meta data-react-helmet="true" property="og:image" content="https://immigrationland.net/assets/immigrationplus.jpg" />
        <meta data-react-helmet="true" property="og:description" content="We offer a free consultation to assess your unique immigration needs and provide personalized support. Our experienced team can handle complicated cases and work to ensure your success." />
      </Helmet>
      <div>
        <Typography paragraph>
          
          <h1 className="text-xl">Immigration</h1>
        </Typography>
      </div>
      <Paper elevation={2} className="dark:bg-[#2c2f33] dark:text-white">
        <div className="p-5">
          <div className="lg:flex justify-center mb-5">
            <div className="lg:w-1/8 mx-auto">
              <ThemeProvider theme={theme}>
                <Button variant="contained" size="large">
                  <Link
                    color={"inherit"}
                    style={{textDecoration: "none", fontWeight: "bold"}}
                    href="/Immigration/LegitimateImmigration"
                  >
                    How to immigrate الهجرة الشرعية
                  </Link>
                </Button>
              </ThemeProvider>
            </div>
            <div className="lg:w-1/8 mx-auto">
              <ThemeProvider theme={theme}>
                <Button variant="contained" size="large">
                  <Link
                    color={"inherit"}
                    style={{textDecoration: "none", fontWeight: "bold"}}
                    href="/Immigration/BeforeYouStartYourImmigration"
                  >
                    Before You Start Your Immigration
                  </Link>
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <Divider className="dark:bg-[#99aab5] clear-both" />

          <div className="lg:flex gap-5 my-5 justify-center">
            <div className="lg:w-1/8 mb-5 mx-auto">
              <ThemeProvider theme={theme}>
                <Button variant="contained" fullWidth>
                  <Link
                    href="/Immigration/USA"
                    color={"inherit"}
                    style={{textDecoration: "none", fontWeight: "bold"}}
                  >
                    <img src={USAIcon} alt="" className="mx-auto" />
                    Immigration to USA
                  </Link>
                </Button>
              </ThemeProvider>
            </div>
          </div>

          <div>
            <Typography paragraph>
              Our office provides you with CD’s/DVD’s, videos, flashcards, and other preparation
              material to help you prepare for your Citizenship, Green Card and Interviews. Please
              take advantage of our free consultation service to see what your unique immigration
              case needs. <Link href="/Contact">Click here to contact us</Link> or call Sherif at
              <Link
                href="tel:714-491-0781"
                title="Call Immigration land in Anaheim, California, USA"
              >
                (714) 491-0781
              </Link>
              .
            </Typography>
          </div>
          <div>
            <ul className="list-disc list-inside mb-5">
              <li>
                If you have any personal or complicated issue, our service is beneficial; we’re on
                your side and we work to help you by using our experience and knowledge to be
                resourceful and precise towards reaching success.
              </li>
              <li>
                It’s better to have professionals handle your immigration papers right away to make
                the process cheaper and more accurate, and your file is yours forever (take into
                consideration that your INS and IRS files are very important and need attention when
                creating and maintaining them). Please note that waiting to begin your process puts
                you at risk of paying more as the NVC fees often increase.
              </li>
            </ul>
          </div>
          <div>
            <Typography>
              We’re experienced, we’re on your side, and we’ll work for your benefit. The Internet
              might be cheaper, but it does not have the personal touch of supporting you in the
              case of a problem (you may not know if you have a problem). Immigration matters are
              important and valuable, and are worth hiring a professional representative to assist
              you with. You are always welcome to call me for a consultation - Sherif
              <Link
                href="tel:714-491-0781"
                title="Call Immigration land in Anaheim, California, USA"
              >
                (714) 491-0781
              </Link>
              .
            </Typography>
          </div>
        </div>
      </Paper>
    </div>
  );
}
