import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import CompanyName from "./CompanyName";
import Developed from "./Developed";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';

const today = new Date();

export default function Footer() {
  return (
    <div className="dark:text-[#99aab5]">
      <div>
        <div className="w-1/2 float-left mb-3">
          <h3 className="text-2xl uppercase"><CompanyName /></h3>
          {/* <img src={ImmigrationPlus} alt="" title="" /> */}
        </div>

        <div className="flex float-right">



          <div className="w-1/3 float-right mb-3 mr-3 text-right">
            <a href="tel:7144910781" target="_blank" rel="noreferrer" title="call Immigration Plus, USA"><CallIcon fontSize="large" /></a>
          </div>

          <div className="w-1/3 float-right mb-3 mr-3 text-right">
            <a href="https://api.whatsapp.com/send?phone=17149246286" target="_blank" rel="noreferrer" title="Chat/call on whatsapp for all Immigration to USA needs"><WhatsAppIcon fontSize="large" /></a>
          </div>

          <div className="w-1/3 float-right mb-3 text-right">
            <a href="https://www.facebook.com/immigrationplususa/" target="_blank" rel="noreferrer" title="Immigration Plus on Facebook"><FacebookIcon fontSize="large" /></a>
          </div>

        </div>
      </div>
      <Divider className="dark:bg-[#99aab5] clear-both" />
      <div className="mt-5">
        <address>
          {/* <div><Typography><CompanyName/></Typography></div> */}
          <div>
            <Typography>
              Tel:
              <Link color={'inherit'}
                href="tel:7144910781"
                title="Call Immigration Plus in Anaheim, California, USA"
                className="dark:text-[#99aab5]"
              >
                (714) 491-0781
              </Link>
              - Cell:
              <Link color={'inherit'}
                href="tel:7149246286"
                title="Call Immigration Plus in Anaheim, California, USA"
                className="dark:text-[#99aab5]"
              >
                (714) 924-6286
              </Link>
            </Typography>
          </div>
          <div>1811 W. Katella Ave. #211 - Anaheim, California 92804 - USA</div>
        </address>
      </div>
      <div>
        <Typography>
          <Link href="/" color={'inherit'} exact="true" className="dark:text-[#99aab5]">
            &copy; {today.getFullYear()} - <CompanyName />
          </Link>
        </Typography>
      </div>
      <div>
        <Typography>
          <Developed />
        </Typography>
      </div>
    </div>
  );
}
